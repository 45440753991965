import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Image from "gatsby-image"
//import { rhythm } from "./utils/typography"
import HeadShot from "../../../content/assets/profile-pic.jpg"
import MugShot from "../../../content/assets/worlds-best-speaker.jpg"

class TeachDontSpeakWorkshopPage extends React.Component
{
    render() {
        const { data } = this.props
        const siteTitle = data.site.siteMetadata.title
        const menu = data.site.siteMetadata.menuLinks
        const tagline = data.site.siteMetadata.tagline
    
        return (
          <Layout location={this.props.location} title={siteTitle} menuLinks={menu} tagline={tagline}>
            <SEO title="Teach, don't speak - Derek's speaking & teaching workshops" />
            


            <h1>Teach, don't speak: the workshop</h1>
            <p class="pub-details">Your teacher: Derek Featherstone</p>


            <img 
              alt="Profile photo of Derek speaking at a conference, wearing a discreet over the ear microphone"
              className="bodyshot"
              src={HeadShot}

            />
            <p><em>If you want to become a better speaker, a better workshop leader, or better presenter, this online workshop will help you. Derek will lead you through an online course that focuses on what you should do as a speaker to ensure that your participants leave inspired, and ready to take action.</em></p>

            <h3>Dates & Location</h3>

            <p>The workshop will take place over 4 days, 90 minutes at a time: <br /><strong>March 24, 26, 31, April 2 2020</strong>.</p>

            <p>As for <strong>location</strong> — that's a trick! It's online, you don't have to go anywhere for it!</p>

            <h2>About the workshop</h2>
            
            <p>TL;DR: I'm running an online workshop for speakers, teachers, facilitators, trainers, and other people that want to be really effective at helping learners meet their learning outcomes. This is not about the mechanics of speaking or presenting. This is not about fear of getting on the stage or impostor syndrome. This is about planning and executing effective presentations and workshops.</p>
            

            <p>The workshop will include:</p>
            <ul><li>The framework I use to plan every single workshop and talk that I prepare.</li>
            <li>A look at examples of my raw notes, the framework, and the final presentations I created from that plan</li>
            <li>Hands-on practical exercises that lead you through the process</li>
            <li>1:1 time with me to give you feedback as you start to implement the framework</li></ul>
 
            <div class="callout">
                <p>Interested, but not quite ready to sign up yet? <a href="https://mailchi.mp/f133934f2b0d/feather">Sign up to let me know you're interested in future workshops</a>. This is a MailChimp list and I'll use it for two things:</p>

            <ol>
              <li>Within 2 hours of you confirming you want to be on the list, I'll send you an outline of what the course includes, and ask for your feedback on it, and what topics you might want to learn about.</li>
              <li>For the next 4 or 5 days, I'll send you some new content every day or so, that serves as a "preview" of the content for the course. I'll also likely ask you some more questions.</li>
            </ol>
              
            <p>That's it! If that sounds good... you should go ahead and <a href="https://mailchi.mp/f133934f2b0d/feather">Sign up on MailChimp</a> and I'll let you know when the next editions of the workshops are.</p></div>


            <h2>Ready to join the next workshop?</h2>
            <p>If you're at all like me and don't really want to wait, you'll want to know a bit more... this next block is for you.</p>

            <h2>Why is Derek doing this now?</h2>

            <p>I've been planning this for at least 6 years. But it hasn't been a reality until now. Why? I've been too afraid. What if it fails? What if nobody wants to do this thing that I want to do?</p>

            <p>Here's the problem: in all of that analysis-paralyis, I have done exactly <em><strong>none</strong></em> of the things that I wanted to do, other than a few false starts that didn't amount to... anything. So I can't even answer the question "What if it fails?" or "What if nobody wants this? and if they don't, why not?" So that has to stop.</p>

            <h2>Here's what I'm doing:</h2>

            <p>I'm going to run a workshop for speakers, trainers, workshop facilitators, presenters, or otherwise. It's going to be online, using Zoom. It'll be held over 4 blocks of 1.5 hours each. There will be homework. You'll be expected to participate. I will invite you into my private Slack to work with me and so I can review some of the homework with you, and you have a chance to ask questions and talk with me and each other and it will be glorious!</p>

            <p>I can hear your questions already... they're churning around in your brain as we speak... The first one is likely: </p>

            <h2>Why should I take this course from you, Derek? I hardly know you</h2>

            <p>That's a fair point. I could provide all kinds of social proof (that's a fancy name for me trying to convince you that thousands of other people can't be wrong, can they?) I could talk to you about all the experience I have, which would be fair because I have quite a bit -- I've been teaching and speaking for nearly three decades. But really, here's the number one reason you should take this course from me:</p>

            <p><strong><em>I'm a teacher.</em></strong> While I started my career teaching high school in 1993, I've been using my teaching background since I started my first company in 1999. I've been using the techniques that I share with you in this course for over 27 years. And, yes, that is painful to write, <em>thankyouverymuch</em>. I've been using these techniques to <strong>create experience-led teaching that focuses on the learner first</strong>, and you can do that too.</p>

            <h2>That's truly it.</h2>

            <p>These techniques haven't let me down in 27 years, but more importantly, they <strong>don't let the learner down</strong>. You've very likely been in those workshops where you come away thinking "Well, there's a day I'll never get back!" You're disappointed, don't know what to do next, and you wonder why you wasted your time and money.</p>

            <p>In this course I want to teach you the techniques and framework that I use for not just TEACHING, but planning what you're going to present and how you're going to present it, so that you can create the most effective learning opportunities for the participants in your class, workshop, or presentation. Because that's where the learner comes first.</p>
            <p>This isn't about you, the workshop facilitator. <strong>This is about the people you're teaching</strong> in your workshops, in the conference room, in the big ballroom when you lead 200 people in a hands-on activity that lasts for 5 minutes, or whatever other teaching scenario you find yourself in.</p>

            <h2>I'm no star</h2>

            <img

              alt="World's best speaker mug"
              className="bodyshot"
              src={MugShot}
              
            />

            <p>I'm not the world's most popular speaker. I'm not the highest paid. I have won some awards for my speaking. Oh! I got this mug one time from a conference I spoke at, so maybe I need to revise the copy in this paragraph?</p>
            
            <p>I'm definitely not perfect, and there are times when I'm off my game and I come away feeling like I just wasn't good enough. But, I'm really, <em>really</em> good at what I do, and those other things don't really matter.</p>
            
            <p>What I care most about is this: if you start using these techniques you'll become a more effective teacher. You'll get your message across more clearly, and learners will thank you for the practical and useful learning experience that you created.</p>

            <h2>What more do you want to know?</h2>

            <p>I've been teaching teachers from near the beginning of my teaching career.</p>
            <p>People tell me that the framework that I use for designing and planning workshops, conference presentations, and other learning opportunities has changed the way they look at teaching and learning, and helps them create presentations faster. It literally helps them plan much more effectively. They're <strong>more confident in their teaching because of these methods</strong></p>

            <h2>The next workshop delivery...</h2>

            <p>I'm anticipating that the next live workshop will be in May or June. Sorry, I can't narrow it down more than that, but that's where we are right now... To stay informed, and learn more from my speaking tips and tricks, you should join my mailing list:</p>

            <a className="mc-cta" href="https://mailchi.mp/f133934f2b0d/feather">Learn more via MailChimp</a>

            <h2>Still here?</h2>

            <p>If you're still here, maybe you have questions... well, I'm here to answer them. Honestly -- <a href="https://twitter.com/feather">DM on twitter</a> is likely best, but I'm happy to receive emails too. My first name @ this domain will work.</p>




            



          </Layout>
        )
      }

}

export default TeachDontSpeakWorkshopPage

export const pageQuery = graphql`
  query {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    mugshot: file(absolutePath: { regex: "/worlds-best-speaker.jpg/" }) {
      childImageSharp {
        fixed(width: 300, height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        title
        tagline
        menuLinks {
          name
          link
        }
      }
    }
  }
`